<template>
    <div class="home-entry">
    <p class="top_text">智慧病房信息发布系统</p>
      <div class="container"></div>
      <div class="container_content">
        <div class="content_one">
          <div class="content_img">
            <div class="img com_img"></div>
          </div>
          <div class="content_text"><h4>健康宣教、科室宣传、出诊信息</h4></div>
        </div>
        <div class="content_one">
          <div class="content_img">
            <div class="img1 com_img"></div>
          </div>
          <div class="content_text"><h4>数据对接、设备运维、辅助决策</h4></div>
        </div>
        <div class="content_one">
          <div class="content_img">
            <div class="img2 com_img"></div>
          </div>
          <div class="content_text"><h4>健康宣教、科室宣传、出诊信息</h4></div>
        </div>
        <div class="content_one">
          <div class="content_img">
            <div class="img3 com_img"></div>
          </div>
          <div class="content_text"><h4>健康宣教、科室宣传、出诊信息</h4></div>
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'HomePage',
  components: { }
}
</script>
  <style scoped lang="less">
  .top_text{
    width: 1024px;
    height: 60px;
    margin: 0 auto;
    text-align: center;
    line-height: 60px;
    font-size: 40px;
}
  .container{
    width: 1024px;
    height: 500px;
    background: url('../../assets/images/banner/plan3.png') no-repeat center / contain;
  }
  .container_content{
    width: 1024px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .content_one{
      width: 100%;
      height: 250px;
      margin-top: 25px;
      margin-bottom: 25px;
      display: flex;
      background-color: white;
      .content_img{
        flex: 0.4;
        .com_img{
          width:320px ;
          height: 180px;
          margin: 0 auto;
          margin-top: 35px;
        }
        .img{
          background: url('../../assets/images/company_img/14.png') no-repeat center / contain;
        }
        .img1{
          background: url('../../assets/images/company_img/13.png') no-repeat center / contain;
        }
        .img2{
          background: url('../../assets/images/company_img/16.png') no-repeat center / contain;
        }
        .img3{
          background: url('../../assets/images/company_img/25.png') no-repeat center / contain;
        }
      }
      .content_text{
        flex: 0.6;
        text-align: center;
        margin-top: 100px;
        font-size: 30px;
        font-weight: bold;
      }
    }
  }
  </style>
